import React from 'react';

const routeComponents = {
  Home: React.lazy(() => import('views/Home')),
  IndexView: React.lazy(() => import('views/IndexView')),
  SiteManager: React.lazy(() => import('views/SiteManager')),
  NotFound: React.lazy(() => import('views/NotFound')),
  Registration: React.lazy(() => import('views/Registration')),
  SelectOrganization: React.lazy(() => import('views/SelectOrganization')),
  SubscriptionManager: React.lazy(() => import('views/SubscriptionManager/SubscriptionManager')),
  SearchManager: React.lazy(() => import('views/SearchManagement/components/SearchManager')),
  ManageProfile: React.lazy(() => import('views/ManageProfile')),
  ContactPageCover: React.lazy(() => import('views/ContactPageCover')),
  CompanyTerms: React.lazy(() => import('views/IndexView/components/CompanyTerms')),
  SitePages: React.lazy(() => import('views/SiteManager/components/SitePages')),
  Dashboard: React.lazy(() => import('views/Dashboard')),
  AddCrawler: React.lazy(() => import('views/SiteManager/components/AddEditCrawler')),
  EditUser: React.lazy(() => import('views/Administration/UserManagement/components/EditUser')),
  AddUser: React.lazy(() => import('views/Administration/UserManagement/components/AddUser')),
  UserManagement: React.lazy(() => import('views/Administration/UserManagement')),
  CodeVerification: React.lazy(() => import('views/Registration/components/RegistrationItems/components/CodeVerification')),
  Organization: React.lazy(() => import('views/Registration/components/RegistrationItems/components/Organization')),
  Subscription: React.lazy(() => import('views/Registration/components/RegistrationItems/components/Subscription')),
  Search: React.lazy(() => import('views/Search')),
  VerifyEmail: React.lazy(() => import('views/Registration/components/RegistrationItems/components/VerifyEmail')),
  NewUserSignUpRedirect: React.lazy(() => import('views/Registration/components/RegistrationItems/components/NewUserSignUpRedirect')),
  SearchManagement: React.lazy(() => import('views/SearchManagement')),
  AddEditConfiguration: React.lazy(() => import('views/SearchManagement/components/AddEditConfiguration')),
  TryMySearch: React.lazy(() => import('views/TryMySearch/')),
  Tutorial: React.lazy(() => import('views/SearchManagement/components/Tutorial')),
  CategoryManager: React.lazy(() => import('views/CategoryManager')),
};

export default routeComponents;

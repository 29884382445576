import { useAuth0 } from '@auth0/auth0-react';
import { useOrgRoleData } from 'core/state/useOrgRoleData';
import { useNavigate } from 'react-router-dom';

export const fetchWithAuth = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [orgRoleData] = useOrgRoleData();
  const navigate = useNavigate();
  const getData = async (
    url: string,
    options: RequestInit,
    overrideGenericErrorHandling?: boolean,
  ) => {
    try {
      // Fetch access token
      const token = await getAccessTokenSilently();

      // Perform the fetch request
      const response = await fetch(url, {
        ...options,
        headers: {
          ...options.headers,
          Authorization: `Bearer ${token}`,
          orgId: orgRoleData?.org?.id,
          'Content-Type': 'application/json',
        },
      });
      // Check if the response status is ok (status code 200-299)
      if (!response.ok && !overrideGenericErrorHandling) {
        navigate(`/error/${response.status}`, {
          state: { statusText: response.statusText },
        });

        throw new Error(
          `Fetch error: ${response.status} ${response.statusText}`,
        );
      }

      return response;
    } catch (error) {
      // Log or handle errors accordingly
      console.error('Error fetching data:', error);
      throw error;
    }
  };

  return { getData };
};

import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Loading } from 'shared/components/LoadingComponent';
import { useLocation } from 'react-router-dom';

export const AuthenticationGuard = ({ component: Component }) => {
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
  const [isAuthorized, setIsAuthorized] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const checkAuthentication = async () => {
      if (isLoading) {
        // Wait for loading
        return;
      }
      if (!isAuthenticated) {
        // Not authenticated, trigger login with redirect to current path after login
        await loginWithRedirect({
          appState: { returnTo: location.pathname + location.search },
        });
      } else {
        // User is authenticated
        setIsAuthorized(true);
      }
    };

    checkAuthentication();
  }, [isAuthenticated, isLoading, loginWithRedirect, location.pathname]);

  if (isLoading || !isAuthorized) {
    return <Loading message="Checking authentication..." />;
  }

  // User is authenticated and authorized, render the component
  return <Component />;
};

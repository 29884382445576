import React, { useEffect, useState, useCallback } from 'react';
import { apiConfig } from 'const/apiConfig.const';
import { fetchWithAuth } from '../auth/fetchWithAuth';
import { useOrgVerificationCode } from 'core/state/useOrgVerificationCode';

export const OrgCodeVerificationGuard = ({ component: Component }) => {
  const { getData } = fetchWithAuth();
  const [canProceed, setCanProceed] = useState(null);
  const { orgVerificationCode, removeOrgVerificationCode } =
    useOrgVerificationCode();

  const checkVerificationCode = useCallback(async () => {
    if (!orgVerificationCode) {
      setCanProceed(true);
      return;
    }

    try {
      const response = await getData(
        apiConfig.api.verifyInvite + orgVerificationCode,
        { method: 'POST' },
      );

      if (response.ok) {
        setCanProceed(true);
        removeOrgVerificationCode();
      } else {
        // **** Check for error message, if the user has already verified or if its a wrong code or wrong email.
        setCanProceed(true);
        removeOrgVerificationCode();
        console.error('Verification failed');
      }
    } catch (error) {
      console.error('Error fetching verification code:', error);
      setCanProceed(false);
    }
  }, [getData, orgVerificationCode, removeOrgVerificationCode]);

  useEffect(() => {
    checkVerificationCode();
  }, [checkVerificationCode]);

  if (canProceed === null) return null;
  if (canProceed) return <Component />;
  return null;
};

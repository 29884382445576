import { variables } from './variables';
const { REACT_APP_API_POINT, REACT_APP_AUTH_API_POINT } = variables;

export const apiConfig = {
  api: {
    user: `${REACT_APP_AUTH_API_POINT}/user/`,
    resetPassword: `${REACT_APP_AUTH_API_POINT}/user/reset-password`,
    verifyInvite: `${REACT_APP_AUTH_API_POINT}/user/verify-invite/`,
    register_organization: `${REACT_APP_AUTH_API_POINT}/register/org`,
    org_users: `${REACT_APP_API_POINT}/organization/users`,
    invite_users: `${REACT_APP_API_POINT}/organization/invite-users`,
    crawl_site: `${REACT_APP_API_POINT}/crawlsite/`,
    crawl_site_activate_pages: `${REACT_APP_API_POINT}/crawlsite/activate-pages`,
    crawl_site_deactivate_pages: `${REACT_APP_API_POINT}/crawlsite/deactivate-pages`,
    search: `${REACT_APP_API_POINT}/search/`,
    allOrgActivePages: `${REACT_APP_API_POINT}/crawlsite/all-org-active-pages`,
    subscription_connect: `${REACT_APP_API_POINT}/subscription/connect/`,
    subscription_user: `${REACT_APP_API_POINT}/subscription/user`,
    subscription_get_customer_portal_session: `${REACT_APP_API_POINT}/subscription/customer-portal-session`,
    userUpdate: `${REACT_APP_API_POINT}/user/`,
    userInvite: `${REACT_APP_API_POINT}/organization/user-invite/`,
    organizationUser: `${REACT_APP_API_POINT}/organization/user/`,
    edit_page: `${REACT_APP_API_POINT}/crawlsite/page/`,
    sendHtml: `${REACT_APP_API_POINT}/email/send-html`,
    apiKeys: `${REACT_APP_API_POINT}/api-key/`,
    pageCategory: `${REACT_APP_API_POINT}/page-category/`,
    analytics: `${REACT_APP_API_POINT}/analytics/`,
    getApiKeys: `${REACT_APP_API_POINT}/public/api-key/key?apiKey=`
  },
};
